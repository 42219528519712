<template>
    <div class="commission-table-list">
        <div class="date-selection-section">
            <DsDatePicker
                v-model="range"
                range
                name="date-range"
                data-qa="date-range"
                :label="$t('publicAffiliate.dateRange')"
                @update:model-value="fetchLedgerBetweenDates"
            />
        </div>

        <div ref="pageRef" class="ledger-table-section">
            <div v-if="affiliateLedgerResult" class="no-ledger-found" data-qa="no-ledger-found">
                <h4>{{ $t('publicAffiliate.noAffiliateLedgerFound') }}</h4>
            </div>
            <DsTableGrid
                v-show="!affiliateLedgerResult"
                ref="table"
                class="ledger-table"
                :data="affiliateLedger"
                data-qa="table-grid-infinite"
                :sort-field="sortField"
                :show-loading="tableStatus === $options.loading"
                infinite
                :sort-ascending="sortAscending"
                @sort="sort"
                @load="loadAffiliateLedger"
            >
                <DsTableGridColumn
                    :label="$t('publicAffiliate.invoiceId')"
                    prop="invoice_id"
                    width="100"
                    sortable
                />

                <DsTableGridColumn
                    :label="$t('publicAffiliate.contact')"
                    prop="contact_first_name"
                    width="150"
                    sortable
                />

                <DsTableGridColumn
                    :label="$t('publicAffiliate.date')"
                    prop="date_earned"
                    width="150"
                    sortable
                >
                    <template #default="data">
                        <span> {{ dateFormatDate(data.date_earned) }} </span>
                    </template>
                </DsTableGridColumn>

                <DsTableGridColumn
                    :label="$t('publicAffiliate.type')"
                    width="150"
                    sortable
                >
                    <template #default="data">
                        <span> {{ commissionType(data) }} </span>
                    </template>
                </DsTableGridColumn>
                <DsTableGridColumn
                    :label="$t('publicAffiliate.amount')"
                    prop="amount_earned"
                    width="100"
                    sortable
                    numeric
                >
                    <template #default="data">
                        <span> {{ $number(data.amount_earned , 'currency') }} </span>
                    </template>
                </DsTableGridColumn>
            </DsTableGrid>
            <ScrollTarget
                v-if="stopLoading"
                :root="$refs.pageRef"
                @intersect="loadAffiliateLedger"
            />
        </div>
        <div v-show="!affiliateLedgerResult" class="total-commission-section">
            <h6>{{ $t('publicAffiliate.commissionSubTotal') }} <span> {{ commissionSubTotal }} </span></h6>
            <h6>{{ $t('publicAffiliate.clawbackText') }} <span> {{ clawBackTotal }} </span></h6>
            <strong>{{ $t('publicAffiliate.totalCommission') }} <span>{{ commissionTotal }}</span></strong>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import axios from 'axios';
import { ScrollTarget } from '@keap-web/shared-ui';
import {
    DsTableGrid,
    DsTableGridColumn,
    DsDatePicker,
} from '@infusionsoft/design-system';

export const IDLE = 'IDLE';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const DISPLAY_DATE_FORMAT_WITH_YEAR = 'MMM D YYYY';
export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export default {
    components: {
        ScrollTarget,
        DsTableGrid,
        DsTableGridColumn,
        DsDatePicker,
    },
    idle: IDLE,
    success: SUCCESS,
    loading: LOADING,

    data() {
        return {
            startDate: moment().startOf('month').format(ISO_FORMAT),
            endDate: moment().endOf('month').format(ISO_FORMAT),
            range: [new Date(moment().startOf('month')), new Date(moment().endOf('month'))],
            affiliateLedger: [],
            sortField: 'clicks',
            nextPage: '',
            sortAscending: true,
            sortOrder: 'affowed_Id',
            orderDirection: 'desc',
            tableStatus: this.$options.idle,
            limit: 10,
            commissionTotal: '',
            clawBackTotal: '',
            commissionSubTotal: '',
        };
    },

    computed: {
        affiliateLedgerResult() {
            return Boolean(this.tableStatus === this.$options.idle && this.affiliateLedger?.length === 0);
        },

        stopLoading() {
            return Boolean(this.nextPage?.length);
        },
    },

    methods: {
        async loadAffiliateLedger() {
            try {
                const params = {
                    filter: `since==${this.startDate};until==${this.endDate}`,
                    page_size: this.limit,
                    order_by: `${this.sortOrder} ${this.orderDirection}`,
                    page_token: this.nextPage,
                };

                this.tableStatus = this.$options.loading;

                const urlParams = new URLSearchParams(window.location.search);

                const appId = urlParams.get('app_Id');
                const siteId = urlParams.get('siteId');


                const baseUrl = window.__KEAP__.VUE_APP_CORE_URL_PATTERN?.replace('{id}', appId);
                const response = await axios.get(`${baseUrl}/app/public/spa/v2/affiliates/public/portal/comm/${siteId}`, { params });

                if (response.data.affiliate_commission_programs.affiliate_commissions.length > 0) {
                    this.affiliateLedger.push(...response.data.affiliate_commission_programs.affiliate_commissions);
                    this.nextPage = response.data.affiliate_commission_programs.next_page_token;
                    this.commissionTotal = this.$number(response.data.affiliate_commission_programs.total_aff_comm_earned, 'currency');
                    this.clawBackTotal = this.$number(response.data.affiliate_commission_programs.total_claw_back, 'currency');
                    this.commissionSubTotal = this.$number(response.data.affiliate_commission_programs.total_comm_earned, 'currency');

                    if (this.$refs.table && response.data.affiliate_commission_programs.affiliate_commissions.length <= this.limit) {
                        this.$refs.table.finishLoading();
                        this.tableStatus = this.$options.success;

                        return;
                    }
                }
            } catch (e) {
                this.tableStatus = this.$options.success;
                this.$refs.table.finishLoading();
            }

            this.tableStatus = this.$options.idle;
        },

        fetchLedgerBetweenDates() {
            if (this.range.length === 2) {
                this.startDate = moment(this.range[0]).format(ISO_FORMAT);
                this.endDate = moment(this.range[1]).endOf('day').format(ISO_FORMAT);
                this.loadNewAffiliates();
            }
        },

        dateFormatDate (date) {
            return moment(date).format(DISPLAY_DATE_FORMAT_WITH_YEAR);
        },

        commissionType (data) {
            return data.amount_earned >= 0 ? this.$t('publicAffiliate.commissionText') : this.$t('publicAffiliate.clawbackText');
        },

        loadNewAffiliates() {
            this.affiliateLedger = [];
            this.sortOrder = 'affowed_Id';
            this.tableStatus = this.$options.idle;
            this.orderDirection = 'desc';
            this.nextPage = '';
            this.loadAffiliateLedger();
        },

        sort({
            field,
            ascending,
        }) {
            this.checkField(field);

            (ascending) ? this.orderDirection = 'asc' : this.orderDirection = 'desc';
            this.affiliateLedger = [];
            this.nextPage = '';
            this.loadAffiliateLedger();
        },

        checkField(fieldName) {
            switch (fieldName) {
            case 'invoice_id':
                this.sortOrder = 'invoice_id';
                break;
            case 'date_earned':
                this.sortOrder = 'date_earned';
                break;
            default:
                this.sortOrder = 'affowed_Id';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .commission-table-list {
        margin-top: $spacing-200;
        .date-selection-section {
            width: 22rem;
        }
    }

    .ledger-table {
        height: px-to-rem(400);
        overflow-y: auto;
        border-bottom: px-to-rem(1px) solid $color-gray-500;
    }

    .total-commission-section {
        padding-top: $spacing-150;
        padding-right: $spacing-150;
        float: right;
        line-height: $line-height-xl;
        font-size: $font-size-md;
        span {
            float: right;
            padding-left: $spacing-500;
            padding-top: $spacing-100;
        }
    }
</style>

