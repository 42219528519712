<template>
    <div>
        <DsListItem
            v-for="link in affiliateLinksInfo"
            :key="link.name"
            next
            :overline="$t('publicAffiliate.affiliateLink')"
            :title="link.name"
            border="inset"
        >
            <template #description>
                {{ linkUrl(link.trackingLink) }}
            </template>

            <template #footer>
                <DsBox padding-top="spacing-200">
                    <DsButtonGroup>
                        <DsFilledButton @click="copyAffiliateLink(link.trackingLink)">
                            {{ $t('publicAffiliate.copyLink') }}
                        </DsFilledButton>

                        <DsOutlineButton @click="openLinkUrl(link.trackingLink)">
                            {{ $t('publicAffiliate.openInTab') }}
                        </DsOutlineButton>
                    </DsButtonGroup>
                </DsBox>
            </template>
        </DsListItem>

        <div v-if="affiliateLinksInfo.length===0" class="no-affiliate-link-record" data-qa="no-links">
            {{ $t('publicAffiliate.noLinkAssigned') }}
        </div>
    </div>
</template>

<script>
import {
    DsBox,
    DsListItem,
    DsButtonGroup,
    DsFilledButton,
    DsOutlineButton,
} from '@infusionsoft/design-system';

export default {
    components: {
        DsBox,
        DsListItem,
        DsButtonGroup,
        DsFilledButton,
        DsOutlineButton,
    },

    props: {
        affiliateLinksInfo: {
            type: Array,
            default: () => ([]),
        },
    },

    methods: {
        linkUrl(url) {
            if (url.length > 30) {
                return url.substr(0, 30).concat('...');
            }

            return url;
        },

        copyAffiliateLink(link) {
            const successMessage = this.$t('publicAffiliate.linkSuccessMessage');
            const errorMessage = this.$t('publicAffiliate.linkCopyError');

            return this.$copyText(link).then(
                () => {
                    this.$toast({
                        message: successMessage,
                    });
                },
                () => {
                    this.$error({
                        message: errorMessage,
                    });
                },
            );
        },

        openLinkUrl(linkUrl) {
            window.open(linkUrl);
        },
    },
};
</script>

<style lang="scss" scoped>
.no-affiliate-link-record {
    padding: $spacing-500 $spacing-200;
}
</style>
