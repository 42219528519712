<template>
    <div>
        <DsListItem
            v-for="commission in commissionData"
            :key="commission.name"
            next
            :overline="$t('publicAffiliate.commissionProgram')"
            :description="commission.notes"
            :title="commission.name"
            border="inset"
        />

        <div v-if="commissionData.length === 0" class="no-commission-program-record" data-qa="no-commissions">
            {{ $t('publicAffiliate.noCommissionAssigned') }}
        </div>
    </div>
</template>

<script>
import { DsListItem } from '@infusionsoft/design-system';

export default {
    components: {
        DsListItem,
    },

    props: {
        commissionData: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>

<style lang="scss" scoped>
.no-commission-program-record {
    padding: $spacing-500 $spacing-200;
}
</style>
